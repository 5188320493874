<template>
  <router-view/>
</template>
<script>
import { core } from './config/pluginInit'
import { PushNotifications } from '@capacitor/push-notifications'
import Axios from 'axios'
import { API } from '@/config/api.config'
import { Capacitor } from '@capacitor/core'

export default {
  name: 'App',
  // sidebarTicket
  data () {
    return {
      device: Capacitor.getPlatform() || 'web'
    }
  },
  components: {},
  mounted () {
  //  this.getLanguages()
    core.mainIndex()
    if (this.device !== 'web') {
      this.$nextTick(function () {
        this.pushNotification()
      })
    }
    this.langChangeState()
  },
  methods: {
    getLanguages () {
      Axios.get(API.API_GET_LANGUAGES)
        .then((response) => {
          if (response.data.status === 'success') {
            localStorage.setItem('languageTranslations', JSON.stringify(response.data.values))
          }
        })
        .catch(() => {
        })
    },
    langChangeState () {
      const currentLangObj = window.location.search
      if (currentLangObj.includes('lang=')) {
        const theCurrentLang = currentLangObj.split('lang=')
        const currentLang = theCurrentLang[1].split('&')
        this.$i18n.locale = currentLang[0]
      }
    },
    pushNotification () {
      const addListeners = async () => {
        await PushNotifications.addListener('registration', token => {
          console.info('Registration token: ', token.value)
          localStorage.setItem('fcm', token.value)
          const postData = {
            token: token.value
          }
          Axios.post(API.API_STORE_TOKEN, postData)
            .then(() => {
              console.log('Push registration success, token: ' + token.value)
            })
            .catch(() => {
            })
        })

        await PushNotifications.addListener('registrationError', err => {
          console.error('Registration error: ', err.error)
        })

        await PushNotifications.addListener('pushNotificationReceived', notification => {
          console.log('Push notification received: ', notification)
        })

        await PushNotifications.addListener('pushNotificationActionPerformed', notification => {
          console.log('Push notification action performed', notification.actionId, notification.inputValue)
        })
      }

      const registerNotifications = async () => {
        let permStatus = await PushNotifications.checkPermissions()

        if (permStatus.receive === 'prompt') {
          permStatus = await PushNotifications.requestPermissions()
        }

        if (permStatus.receive !== 'granted') {
          throw new Error('User denied permissions!')
        }

        await PushNotifications.register()
      }

      const getDeliveredNotifications = async () => {
        const notificationList = await PushNotifications.getDeliveredNotifications()
        console.log('delivered notifications', notificationList)
      }

      const newChannel = async () => {
        await PushNotifications.createChannel({
          description: 'General Notifications',
          id: 'fcm_default_channel',
          importance: 5,
          lights: true,
          name: '12booky',
          sound: 'onetwobooky.wav',
          vibration: true,
          visibility: 1
        }).then(() => {
          console.log('push channel created')
        }).catch(error => {
          console.error('push channel error: ', error)
        })
      }
      console.log(getDeliveredNotifications(), registerNotifications(), addListeners(), newChannel())
    }
  }
}
</script>
<style lang='scss'>
  @import 'assets/scss/style.scss';
</style>
